// core
import React, { FC } from 'react';
// components
import Preloader from '../preloader';
// instruments
import { useAuth0 } from '../../helpers/auth';

const PageTrial: FC = () => {
  // hooks
  const { loginWithRedirect } = useAuth0();

  const redirectParams = {
    targetUrl: window.location.origin,
    pathname: '/',
    screen_hint: 'signUp',
    trial: true,
  };

  loginWithRedirect(redirectParams);

  return <Preloader />;
};

export default PageTrial;
